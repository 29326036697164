// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Your primary main color
            light: '#98d9eb',
            dark: '#1b363d'
        },
        secondary: {
            main: '#dc004e', // Your secondary main color
        },
        background: {
            default: '#9ad4dd', // Your default background color
            darker: "#a3cad4",
            paper: '#ffffff', // Your paper background color
            cards: '#9ad4dd', // Your paper background color
        },
    },
    // Add more customizations here
});

export default theme;
