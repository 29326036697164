import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {AuthContext} from "../authorization_state";

function ReadCalendars() {
    const [calendars] = useState([]);
    const [events] = useState([]);
    const {user} = useContext(AuthContext);
    // const request = useDirectusRequest();
    // const [message, setMessage ] = useState(null)
    // const saveNewEvents = async (events) => {
    //     const newData = {
    //         time_slots: {
    //             create:  events.map(event =>
    //                 ({
    //                     start: event.start,
    //                     end: event.end,
    //                     calendar_name: event.summary,
    //                     user: user.id
    //                 }))
    //         }
    //     }
    //     console.log("saveNewEvents:",newData)
    //     try {
    //         // Save events to the database
    //         await request(updateMe(newData));
    //     }
    //     catch (error) {
    //         console.error('An error occurred:', error);
    //
    //     }
    // }
    useEffect(() => {
        // calendarTest().then(events => {
        //   saveNewEvents(events)
        // })
        // const fetchData = async () => {
        //     try {
        //         const result = directusClient.request(inviteUser("wolfgang.wenzel@kit.edu",))
        //     }
        // }

        const url = "https://www.meet-fast.com/flows/trigger/cf02583c-174d-4fa0-97c6-9c7648c48ecd"
        axios.post(url, {message: 'hello world'}).then(response => {
            console.log("response", response.data)
        }).catch(error => {
            console.error('An error occurred:', error);
        })



    }, [user]);

    return (
        <div>
            <h2>Shared Calendar Names</h2>
            {calendars.length > 0 ? (
                <ul>
                    {calendars.map(item => (
                        <li key={item.id}> {item.name}</li>
                    ))}
                </ul>
            ) : (
                <p>No shared calendars found.</p>
            )}
            <h2>Events</h2>
            {events.length > 0 ? (
                <ul>
                    {events.map((item, id) => (
                        <li key={id}> {new Date(item.start.dateTime).toLocaleString()}
                            {new Date(item.end.dateTime).toLocaleString()} {item.subject}</li>
                    ))}
                </ul>
            ) : (
                <p>No shared calendars found.</p>
            )}
        </div>
    );
}

export default ReadCalendars;