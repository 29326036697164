import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {directusClient} from "../utils/directus";
import {createItem, readMe} from "@directus/sdk";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Button from "@mui/material/Button";

function MeetingList() {
    const navigate = useNavigate();
    const [meetingList, setMeetingList] = useState([]);
    const [user,setUser] = useState(null);

    async function getMeetingList() {
        return directusClient.request(readMe({
            fields: ["*.*.*"],
        })).then(user  => {
            setUser(user)
            setMeetingList(user.meetings);
            return Promise.resolve(user);
        }).catch(error => {
            console.log('meeting_list.js could not read user:', error);
        })
    }

    async function makeMeeting(user_id) {
        return directusClient.request(createItem('meeting',
            {
                title: "Meeting Template",
                owner_id:user_id,
                duration: 60,
                earliest_start: new Date(),
                status: "scheduling",
                skip_lunch: "yes",
                workday_starts: "09:00",
                workday_ends: "17:00",
                lunch_starts: "12:00",
                lunch_ends: "13:00"
            })).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    async function createMeeting() {
        try {
            let meetingResponse = await makeMeeting(user.id);
            console.log('meeting_list.js createMeeting: OK', meetingResponse);
            setMeetingList([...meetingList, meetingResponse]);
            // navigate to the new meeting
            navigate(`/meeting/${meetingResponse.id}`);
        } catch (error) {
            console.log('meeting_list.js createMeeting error:', error);
        }
    }

    useEffect(() => {
        getMeetingList().then(user => {
            console.log('meeting_list.js read meetings for user',
                user.first_name, user.last_name)
        }).catch(error => {
            console.log('meeting_list.js getMeetingList error:', error);
        })
    }, []);

    return (
        <Box pt={2} pr={5} pl={5}>
            {/*<Typography variant="h2"> Meeting List </Typography>*/}
            <Button variant="contained" color="primary" pb={2} onClick={createMeeting}>
                Create New Meeting
            </Button>
            <TableContainer component={Paper} >
                <Table pt={2} pb={2}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meetingList.map((meeting) => (
                            <TableRow key={meeting.id} onClick={() => navigate(`/meeting/${meeting.id}`)}>
                                <TableCell>{meeting.title}</TableCell>
                                <TableCell>to be done</TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default MeetingList;
