import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment/moment";
import {
    Grid, MenuItem,
    Select,
    Typography
} from "@mui/material";

import React from 'react';
import './meeting_info.css';
import Button from "@mui/material/Button";
import {DateTimeField} from "@mui/x-date-pickers";
import WorkingPeriod from "./working_period";

function MeetingInfo({timeInfo, saveTimeInfo}) {

    function convertToMoment(date) {
        return moment(date);
    }

    function setEarliestStart(moment)
    {
        // convert moment to isofomat
        const iso = moment.toISOString();
        console.log('meeting_info.js setEarliestStart', iso);
        saveTimeInfo({earliest_start: iso});
    }


    function setSchedulePeriod(days){ saveTimeInfo({schedule_period: days}) }
    function setDuration(minutes){ saveTimeInfo({duration: minutes}) }


    // initial state

    return (
        <div>
            { timeInfo !== null && Object.keys(timeInfo).length !== 0  ?
            <Grid container={true} spacing={2} alignItems="center" justifyContent="left">
                <Grid item xs={3}>
                    <Typography>Earliest Meeting Start</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <div className="datePicker">
                                <DateTimeField
                                    label="Earliest Meeting Start"
                                    value={convertToMoment(timeInfo.earliest_start)}
                                    onChange={(newValue) => {
                                        setEarliestStart(newValue);
                                    }}
                                    // renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Duration</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={timeInfo.duration}
                        label="Maximum Number of Days"
                        onChange={(event) => setDuration(event.target.value)}
                    >

                        <MenuItem value={30}>30 minutes</MenuItem>
                        <MenuItem value={60}>one hour </MenuItem>
                            <MenuItem value={90}>90 minutes</MenuItem>
                            <MenuItem value={120}>two hours</MenuItem>
                            <MenuItem value={180}>three hours</MenuItem>

                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Typography pr={5}> Schedule Period</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={timeInfo.schedule_period}
                        label="Maximum Number of Days"
                        onChange={(event) => setSchedulePeriod(event.target.value)}
                    >

                        <MenuItem value={7}>One Week</MenuItem>
                        <MenuItem value={14}>Two Weeks</MenuItem>
                        <MenuItem value={21}>Three Weeks</MenuItem>
                    </Select>
                </Grid>
                <WorkingPeriod information={timeInfo} saveInformation={saveTimeInfo}/>
                <Grid item xs={2}>
                    <Button> Generate</Button>
                </Grid>
            </Grid>
                : "loading ...." }
      </div>
  );
}

export default MeetingInfo;