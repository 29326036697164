import { Box, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const theme = useTheme();

  return (
    <div>
      <footer>
        <Box sx={{ display: 'flex',  backgroundColor: theme.palette.primary.light }}>
          <List dense>
            <ListItem>
              <Link to={`/terms_of_service`}>
                Terms of Service
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/privacy_policy`}>
                Privacy Policy
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/Impressum`}>
                Impressum
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/contact" color="inherit">
                Contact
              </Link>
            </ListItem>
          </List>
        </Box>
      </footer>
    </div>
  )
}

export default Footer;