import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {AddCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";


const BulletPointCard = ({content}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const CardListItem = ({line1, line2}) => {
        const theme = useTheme();
        return (
            <ListItem disablePadding alignItems="flex-start">
                <ListItemIcon>
                    <AddCircle style={{ color:  theme.palette.primary.main }} />
                </ListItemIcon>
                <ListItemText
                    sx={{
                        // Apply styles to the secondary text
                        '& .MuiListItemText-secondary': {
                            color: 'green', // Replace 'green' with the color you want
                        },
                    }}
                    primary={line1}   secondary= { isHovered ? line2 : "" } />
            </ListItem>
        )
    }

    const buttonClicked = () => {
        navigate('/explain_teaching');
    }

    return (
        <Card
            onMouseEnter={() => setIsHovered(true)}  // Mouse enters the area
            onMouseLeave={() => setIsHovered(false)} // Mouse leaves the area
            sx={{
                maxWidth: 345,
                height: isHovered ? '490px' : '350px',
                backgroundColor: theme.palette.background.cards,
                '&:hover': {
                    boxShadow: '0 6px 12px 0 rgba(0,0,0,0.2)', // Add a hover shadow
                },
                transition: 'box-shadow 0.3s', // Smooth transition for the shadow
                cursor: 'pointer', // Change cursor on hover
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {content.title}
                </Typography>
                <List>
                    <CardListItem line1={content.line1} line2={content.secondary1}/>
                    <CardListItem line1={content.line2} line2={content.secondary2}/>
                    <CardListItem line1={content.line3} line2={content.secondary3}/>
                </List>

                <CardActions sx={{ justifyContent: 'flex-end', paddingBottom: '16px' }}>
                    <Button size="small" color="primary" onClick={buttonClicked}>
                        Learn More
                    </Button>
                </CardActions>
            </CardContent>
        </Card>
    );
};

export default BulletPointCard;

