import React, {useEffect, useState} from "react";
import { Grid, Paper } from "@mui/material";
import {directusClient} from "../utils/directus";
import {createItem, deleteItem, readItems, readMe} from "@directus/sdk";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";

function Tile({dayIndex, hourIndex, slot, slotChanged}) {
    const handleClick = () => {
        console.log(`Tile clicked: day ${dayIndex}, hour ${hourIndex}`);
        slotChanged({dayIndex, hourIndex});
    };
    return (
        <Paper onClick={handleClick}> {slot[dayIndex][hourIndex]}</Paper>
    );
}

function TimeSlots({setIsLoggedIn}) {
    const [user, setUser] = useState(null);
    const [dataBase, setDataBase] = useState(null);
    const localizer = momentLocalizer(moment)
    const [events, setEvents] = useState([]);
    const [startOfDay, setStartOfDay] = useState(8);
    const [endOfDay, setEndOfDay] = useState(18);

    async function getUser() {
        return new Promise((resolve,reject) => {
            directusClient.request(readMe({fields: ['*']})).then(response => {
                resolve(response);
            }).catch(error => {
                setIsLoggedIn(false);
                reject(error);
            });
        });
    }
    async function getItems(currentUser) {
        return new Promise((resolve, reject) => {
            directusClient.request(
                readItems('time_slot', {
                    fields: ['*'],
                    filter: {
                        user: {
                            _eq: currentUser,
                        },
                    },
                })).then(response => {
                console.log('getItems: OK', response);
                resolve(response);
            }).catch(error => {
                console.log('getItems: error', error);
                reject(error);
            });
        });
    }

    useEffect(() => {
        getUser().then(response => {
            setUser(response);
            getItems(response.id).then(response => {
                const events = response.map(item => ({
                    start: new Date(item.start),
                    end: new Date(item.end),
                    title: item.mode
                }));
                setEvents(events);
                setDataBase(response);
            })
        }).catch(error => {
            setIsLoggedIn(false);
        });
    }, [])

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                min={new Date(0, 0, 0, startOfDay)} // Set start time
                max={new Date(0, 0, 0, endOfDay)} // Set end time
                defaultView="week" // Set initial display to week
                style={{height: 500}}
            />
        </div>
    )

}

function TimeSlotsOld() {
    const [slot, setSlot] = useState([]);
    const [weekNumber, setWeekNumber] = useState(0);
    const [year, setYear] = useState(0);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    // this is an array of the time_slots that the current user has for the current week in the database
    const [dataBase, setDataBase] = useState(null);
    const [dayStart, setDayStart] = useState(8);
    const [dayEnd, setDayEnd] = useState(18);

    const hours = Array.from({length: dayEnd-dayStart+1}, (_, i) => i + dayStart); // Creates an array [8, 9, 10, ..., 18]
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    async function slotChanged({dayIndex, hourIndex, }) {
        // check if the user has already a time_slot for this day and hour
        // if yes, delete it
        const matchingEntries = dataBase.filter(entry => entry.day === dayIndex && entry.hour === hourIndex);

        if (matchingEntries.length > 1) {
            setError(new Error('More than one matching database entry was found.'));
        } else if (matchingEntries.length === 1) {
            const entry = matchingEntries[0];
            console.log('Matching database entry was found:', entry);
            if (entry.status === "busy") {
                directusClient.request(deleteItem('time_slot', entry.id)).then(response => {
                    console.log('deleteOne: OK', response);
                }).catch(error => {
                    console.log('deleteOne: error', error);
                    setError(error);
                });
            }
        } else {  // if no, create it
            directusClient.request(createItem('time_slot',
                {
                    user: user,
                    year: year,
                    week: weekNumber,
                    day: dayIndex,
                    hour: hourIndex + dayStart,
                    status: "busy",
                })).then(response => {
                console.log('created Item: OK', response);
            }).catch(error => {
                console.log('deleteOne: error', error);
                setError(error);
            });
        }
        // if no, create it

        const newSlot = [...slot];
        newSlot[dayIndex][hourIndex] = newSlot[dayIndex][hourIndex] === "free" ? "busy" : "free";
        setSlot(newSlot);
    }

    async function getUser() {
        return new Promise((resolve,reject) => {
            directusClient.request(readMe({fields: ['*']})).then(response => {
                resolve(response);
            }).catch(error => {

                setError(error)
                reject(error);
            });
        });
    }
    async function getItems(initialSlots, currentUser, year, weekNumber) {
        return new Promise((resolve, reject) => {
                directusClient.request(
                    readItems('time_slot', {
                        fields: ['*'],
                        filter: {
                            user: {
                                _eq: currentUser,
                            },
                            year: {
                                _eq: year,
                            },
                            week: {
                                _eq: weekNumber,
                            }
                        },
                    })).then(response => {
                    console.log('getItems: OK', response);
                    response.forEach(item => {
                        if (item.status === "busy" && item.hour >= dayStart && item.hour <= dayEnd) {
                            initialSlots[item.day][item.hour-dayStart] = "busy";
                        }
                    });
                    setDataBase(response)
                    resolve(response);
                }).catch(error => {
                    console.log('getItems: error', error);
                    setError(error);
                    reject(error);
                });
            }
        );
    }

    useEffect(() => {
        const initialSlots = days.map(() => new Array(hours.length).fill("free"));


        const now = new Date();
        let dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
        let startOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek));
        startOfWeek.setDate(startOfWeek.getDate() + (dayOfWeek === 0 ? -6 : 1)); // If it's Sunday, go to previous Monday. Otherwise, go to next Monday.
        startOfWeek.setHours(8, 0, 0, 0); // Set the time to the start of the day

        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const weekNumber = Math.ceil((((now - startOfYear) / 86400000) + startOfYear.getDay() + 1) / 7);
        setWeekNumber(weekNumber);
        setYear(now.getFullYear());
        console.log(`The current week number is ${weekNumber}, the year is: ${now.getFullYear()}`);
        getUser().then(response =>
        {
            setUser(response.id);
            getItems(initialSlots,response.id, now.getFullYear(), weekNumber);
        }).catch(error => { setError(error); });
        setSlot(initialSlots);
    }, []);

    return (
        error ? <div>Error: {error.message}</div> :
            slot && slot.length === 0  ? <div>Loading...</div> :
            <Grid container spacing={2}>
                <Grid item xs={2} key={"hello"}>
                    <Paper>Time</Paper>
                </Grid>
                {days.map((day, dayIndex) => (
                    <Grid item xs={2} key={day}>
                        <Paper>{day}</Paper>
                    </Grid>
                ))}

                {hours.map((hour, hourIndex) => (
                    <React.Fragment key={hour}>
                        <Grid item xs={2}>
                            <Paper>{hour}:00</Paper>
                        </Grid>
                        {days.map((day, dayIndex) => (
                            <Grid item xs={2} key={day}>
                               <Tile dayIndex={dayIndex} hourIndex={hourIndex} slot={slot} slotChanged={slotChanged}/>
                            </Grid>
                        ))}
                    </React.Fragment>
                ))}
            </Grid>
        );
}

export default TimeSlots;