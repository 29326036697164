
import {Grid} from "@mui/material";
import BulletPointCard from "./bulletpointcard";

const CardsRow = () => {

    const content1 = {
        title: 'For Organizers: Schedule Meetings in Seconds',
        line1: 'Define the attendees of the meeting.',
        secondary1: "Copy-and-paste the email addresses of the participants." ,
        line2: 'Find the best time for the meeting based on calendar data of the attendees.',
        secondary2: "Reduced Scheduling Effort: No more back-and-forth emails" ,
        line3: 'Differentiate between different attendee categories',
        secondary3: "Attendees need to accept the meeting but are no longer required to select time slots.",
        url: "/how-it-works"
    };

    const content2 = {
        title: 'For Attendees: 3 Ways to define your availability',
        line1: 'Synchronize your calendar automatically',
        secondary1: 'Available for: Microsoft, Google (working) and more to come.' ,
        line2: 'Define white-listed and black-listed times online.',
        secondary2: 'Permanent and temporary availability settings are possible',
        line3: 'Prioritize existing appointments.',
        secondary3: "Enable rescheduling of more flexible appointments (planned).",
        url: "/how-it-works"
    };

    const content3 = {
        title: 'Control & Privacy',
        line1: 'You stay in control of your calendar. You will always have the opportunity to reject appointment suggestions',
        secondary1: 'Our aim is to improve the suggestions to the point that your intervention is rarely, if ever, required. ',
        line2: 'No personal information is stored.',
        secondary2: 'Calendar information is evaluated, but only the time-slots where you cannot attend are stored.',
        line3: "Control your availability online",
        secondary3: "Augment calender data with your own availability settings online." ,

        url: "/how-it-works"
    };


    return (
        <Grid container spacing={2} justifyContent="center" sx={{ pb: 8 }}>
            <Grid item xs={12} sm={6} md={4} >
                <BulletPointCard content={content1}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BulletPointCard content={content2}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BulletPointCard content={content3}/>
            </Grid>
        </Grid>
    );
};

export default CardsRow;
