import {useState} from "react";
import TextField from "@mui/material/TextField";
import {Grid, IconButton, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

function EditableTextField({input_text, save_title, ...otherProps})    {
    const [text, setText] = useState(input_text);
    const [isEditing, setIsEditing] = useState(false)

    const variantToFontSize = {
        'h1': '2rem',
        'h2': '1.5rem',
        'h3': '1.17rem',
        'h4': '1.12rem',
        'h5': '0.83rem',
        'h6': '0.75rem',
        'subtitle1': '0.875rem',
        'subtitle2': '0.775rem',
        'body1': '1rem',
        'body2': '0.875rem',
    };


    function getFontSize(variant) {
        if (variant in variantToFontSize)
            return variantToFontSize[variant];
        else
            return variantToFontSize['body1'];
    }

    const handleSave = () => {
        setIsEditing(false);
        save_title(text);
    }

    return (
        text !== null ?
           isEditing ?
               <Grid container justifyContent="center" alignItems="center" pb={2}>
                   <Grid item>
                       <TextField
                           value={text}
                           onChange={(e) =>
                               setText(e.target.value)}
                           inputProps={{style: {fontSize: getFontSize(otherProps.variant)}}} // match the font size with Typography

                       />
                   </Grid>
                   <Grid item>
                       <IconButton onClick={() => handleSave()}>
                           <SaveIcon />
                       </IconButton>
                   </Grid>
               </Grid>
               :
               <Grid container justifyContent="center" alignItems="center" pb={2}>
                   <Grid item>
                       <Typography {...otherProps} > {text}</Typography>
                   </Grid>
                   <Grid item>
                       <IconButton onClick={() => setIsEditing(true)}>
                           <EditIcon />
                       </IconButton>
                   </Grid>
               </Grid>
            : "loading title..."

    );
}

export default EditableTextField;