import {directusClient} from "./directus";
import {createItem, createUser} from "@directus/sdk";


const useCreateUser = () => {

    async function create_user({email, password, first_name, last_name}) {
        function generateRandomString(length) {
            return [...Array(length)]
                .map(() => (~~(Math.random() * 36)).toString(36))
                .join('');
        }

        const token = generateRandomString(10);

        try {
            const user = await directusClient.request(createUser({
                email: email,
                password: password,
                first_name: first_name,
                last_name: last_name,
                status: 'suspended',
                role: '639ad816-4897-474b-abf4-9c74d4a56838',
            }))

            console.log('create_user', user)

            await directusClient.request(createItem('validation_keys', {
                email: email,
                validation_key: token,
                user_id: user.id
            }))

        } catch (error) {
            return Promise.reject(error);
        }
    }

    return create_user;
}
export default useCreateUser;