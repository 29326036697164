import React from 'react';
import {Grid, Typography} from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Logo = `${process.env.PUBLIC_URL}/Calendar_Icon.png`;
const Header = () => {
    const theme = useTheme();

    const iconStyle = {
        height: 'inherit',
        verticalAlign: 'bottom',
    };

    const h1ContainerStyle = {
        fontSize: 'inherit',
    };

    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            spacing={2}
            p={2}
            style={{ backgroundColor: theme.palette.background.default }}
        >
            <Grid item style={h1ContainerStyle}>
                <Typography variant="h1">
                    <img src={Logo} width={100} alt="icon" style={iconStyle} />
                </Typography>
            </Grid>
            <Grid item xs={8} md={3} >
                <Typography variant="h2" color="primary.main">Meet-Fast</Typography>
            </Grid>
            <Grid item xs={8} md={7}>
                <Typography variant="h5" color="primary.main">Schedule Meetings in Seconds</Typography>
            </Grid>

        </Grid>
    )
}


export default Header;
