// CookieConsent.js
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {Link} from "react-router-dom";

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(['cookieConsent']);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!cookies.cookieConsent) {
            setVisible(true);
        }
    }, [cookies]);

    const handleAccept = () => {
        setCookie('cookieConsent', true, { path: '/', maxAge: 31536000 });
        setVisible(false);
    };

    if (!visible) {
        return null;
    }

    return (
        <div style={{ position: 'fixed', bottom: '10px', width: '100%', backgroundColor: '#000', color: '#fff', textAlign: 'center', padding: '10px', zIndex: '1000' }}>
            <p>
                We use functional cookies to operate this site. By using this site, you accept our{' '}
                <Link to={`/terms_of_service`}>terms of service</Link>
            </p>
            <button onClick={handleAccept} style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', padding: '10px 20px', cursor: 'pointer' }}>
                Accept
            </button>
        </div>
    );
};

export default CookieConsent;
