import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';

import {directusClient} from "../utils/directus";
import {readItems} from "@directus/sdk";
import {Accordion, AccordionSummary, AccordionDetails, Typography, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//
// const MarkdownContent = ({ content }) => (
//     <ReactMarkdown children={content}
//                    components={{
//                        // Override the default rendering of `a` tags
//                        a: ({node, ...props}) => {
//                            return <a {...props} target="_blank" rel="noopener noreferrer" />;
//                        }
//                    }}
//     />
// );

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const renderers = {
    code: ({language, value}) => {
        return <SyntaxHighlighter style={dark} language={language} children={value} />
    },
    link: ({href, children}) => {
        return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
    }
};

const MarkdownContent = ({ content }) => (
    <ReactMarkdown
        children={content}
        components={{
            code: ({node, inline, className, children, ...props}) => {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match
                    ? <SyntaxHighlighter style={dark} language={match[1]} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
                    : <code className={className} {...props} >{children}</code>
            },
            a: ({node, ...props}) => {
                return <a {...props} target="_blank" rel="noopener noreferrer" />;
            }
        }}
    />
);
export default function FaqPage() {
    const [faqEntries, setFaqEntries] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    async function get_faq() {
        try {
            const faq_data = await directusClient.request(readItems('faq_entry'))
            const faq_object = {
                title: "Frequently Asked Questions",
                rows: faq_data
            }
            setFaqEntries(faq_object)
            setIsLoading(false)
        }
        catch (error) {
            console.log("faq: failed to load")
        }
    }

    useEffect(() => {
        get_faq()
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Box pr={2}>
            {faqEntries.rows.map((row, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <Typography variant="h3">{row.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MarkdownContent content={row.content} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}