import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";

const Contact = () => {
    return (
        <Box  pr={10} pl={10} pt={5} pb={5}>
            <Typography>
                We are working on a contact form. <br/>
                Meanwhile you can contact us by sending an email to &nbsp; <br/>
                <Link to="mailto:webmaster@meet-fast.com">webmaster@meet-fast.com</Link>
            </Typography>
        </Box>
    )
};

export default Contact;