import {directusClient} from "../utils/directus";
import {readSingleton} from "@directus/sdk";
import React, {useEffect, useState} from "react";
import {Box, Link, List, ListItem, Typography} from "@mui/material";
import { useParams } from 'react-router-dom';

function SinglePage() {
    const [page, setPage] = useState(null);
    const { page_name} = useParams();
    async function getContent() {
        return directusClient.request(readSingleton(page_name)).then(response => {
            console.log('single_page.js getContent: OK', page_name, response);
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    useEffect(() => {
        getContent().then(response => {
            console.log('single_page.js useEffect: OK', response);
            setPage(response);
        }).catch(error => {
            console.log('single_page.js useEffect: error', error);
        })
    }, );

    return (
        <Box pr={10} pl={10}>
            { page !== null ?
            <List>
                <ListItem>
                    <div dangerouslySetInnerHTML={{__html: page.content }}/>
                </ListItem>
                <ListItem>
                    <div>Last Updated: { new Date(page.date_updated).toLocaleDateString() } </div>
                </ListItem>
                <ListItem>
                    <Typography>
                        You can contact us by sending an email to &nbsp;
                        <Link href="mailto:webmaster@meet-fast.com">webmaster@meet-fast.com</Link>
                        or by using the &nbsp;
                        <Link to="/contact" color="inherit">
                            contact form
                        </Link>.
                    </Typography>
                </ListItem>
            </List> : "loading ..."
            }
        </Box>
    )
}

export default SinglePage;