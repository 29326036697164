import React, { useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {directusClient} from "../utils/directus";
import {deleteItem, readItems} from "@directus/sdk";
import useCreateUser from "../utils/create_user";

const Register = () => {
    const create_user = useCreateUser();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: ""
    });
    const [status, setStatus] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);

    const isFormValid = formData.email && formData.password && formData.first_name;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleTermsChange = (event) => {
        setTermsAccepted(event.target.checked);
    };

     const handlePrivacyChange = (event) => {
        setPrivacyAccepted(event.target.checked);
    };


    async function deleteOldKeys(email) {
        return directusClient.request(readItems('validation_keys', { filter: { email: email } }))
            .then(response => {
                response.forEach(key => {
                    console.log('deleting key', key);
                    directusClient.request(deleteItem('validation_keys', key.id));
                })
            }).catch( error => {
                console.error('error', error);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!termsAccepted) {
            setStatus({
                type: "error",
                message: "You must accept the terms of service to register.",
            });
            return;
        }
        if (!privacyAccepted) {
            setStatus({
                type: "error",
                message: "You must accept the privacy policy to register.",
            });
            return;
        }
        return  deleteOldKeys(formData.email).then(() => {
            return create_user(formData).then(
                setStatus({
                    type: "success",
                    message: "Registration successful. Please check your email for a validation link"
                })).catch((error) => {
                    let msg = "An unknown error has occurred"
                    if (error && error.errors.length > 0)
                        msg = error.errors[0].message
                    setStatus({
                    type: "error",
                    message: "An error occurred during registration." + msg,
                })
            })
        })
    };

    return (
        <Container maxWidth="sm" >
            <h1>Register</h1>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    value={formData.first_name}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    value={formData.last_name}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={formData.password}
                    onChange={handleChange}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={handleTermsChange}
                            name="termsAccepted"
                            color="primary"
                        />
                    }
                    label="I accept the terms of service"
                />


                <FormControlLabel
                    control={
                        <Checkbox
                            checked={privacyAccepted}
                            onChange={handlePrivacyChange}
                            name="privacyAccepted"
                            color="primary"
                        />
                    }
                    label="I accept the privacy policy"
                />


                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isFormValid}

                >
                    Register
                </Button>
            </form>
            {status && (
                <Alert  severity={status.type} sx={{ marginTop: 2, marginBottom: 2 }}>
                    {status.message}
                </Alert>
            )}
        </Container>
    );
};

export default Register;
