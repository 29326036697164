import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment-timezone'
import {useEffect, useState} from "react";
import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import useDirectusRequest from "../utils/directus";
import {updateMe} from "@directus/sdk";
import * as Sentry from '@sentry/react';

const localizer = momentLocalizer(moment)

const MeetingCalendar = ({user, initialEventsList}) => {
    // MeetingCalendar component
    // can be used to display and edit a user's calendar
    // user: the user object
    // can be used to display the meeting slots while scheduling a meeting
    // user: meeting

    const request = useDirectusRequest();
    const [eventsList, setEventsList] = useState([]);
    const [savedEventsList, setSavedEventsList] = useState([]); // New state variable for savedEventsList
    const [eventsChanged, setEventsChanged] = useState(false);
    const [mode, setMode] = useState('Blocked'); // New state variable for mode


    // const navigate = useNavigation();
    // useBlocker(
    //     (transition) => {
    //         if (eventsChanged) {
    //             if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
    //                 // If the user confirms, retry the transition
    //                 transition.retry();
    //             }
    //         }
    //     });


    useEffect(() => {
        if (initialEventsList){
            //setEventsList(initialEventsList);
            //setSavedEventsList(initialEventsList);
            setLunchTimeSlots(initialEventsList)
        }
    }, [initialEventsList]);

    // Helper function to convert a day and time to a moment object
    function convertDayTimeToMoment(day, timeString) {
        const [hours, minutes] = timeString.split(":");
        return moment().day(day).hours(hours).minutes(minutes);
    }

    const setLunchTimeSlots = (currentEventsList) => {
        // if (eventsList.length === 0) return;
        const noLunchTimeSlots = currentEventsList.filter(event => event.title !== 'Lunch');
        const lunchTimeSlots = currentEventsList.filter(event => event.title === 'Lunch');

        if (user) {
            if (user.skip_lunch === "yes")
            {
                const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                // Loop over the days array
                const lunchEvents = days.map(day => {
                    // Create a new event for each day
                    return {
                        user: user.id,
                        start: convertDayTimeToMoment(day, user.lunch_starts).toDate(),
                        end: convertDayTimeToMoment(day, user.lunch_ends).toDate(),
                        title: 'Lunch',
                        mode: 'Lunch'
                    };
                });
                console.log("Lunchtime Slots: ", lunchEvents)
                console.log("Other Slots: ", noLunchTimeSlots)
                // Merge the lunch events with the existing events
                const updatedTimeSlots = [
                    ...noLunchTimeSlots,
                    ...lunchEvents
                ]
                setSavedEventsList(updatedTimeSlots);
                setEventsList(updatedTimeSlots)
                // Lunch time slots are always in the savedEventsList so that
                // they are not written to the database
                setEventsChanged(false)
            }
            else
            {
                setSavedEventsList(noLunchTimeSlots);
                setEventsChanged(false)
                setEventsList(noLunchTimeSlots)

            }
        }
    }

    useEffect(() => {
       setLunchTimeSlots(eventsList)
    },[user.skip_lunch, user.lunch_starts, user.lunch_ends]);

    // useEffect( () => {
    //     setLunchTimeSlots()
    // }, [user] );

    const saveAction = async () => {
        if (eventsChanged) {
            const newData = {
                time_slots: {
                    create:  eventsList.filter(event =>
                        // the event is not already saved
                        !savedEventsList.some(savedEvent =>
                            savedEvent.start.getTime() === event.start.getTime() &&
                            savedEvent.end.getTime() === event.end.getTime() &&
                            savedEvent.title === event.title)
                    ),
                    delete: savedEventsList.filter(savedEvent =>
                        !eventsList.some(event =>
                            event.start.getTime() === savedEvent.start.getTime() &&
                            event.end.getTime() === savedEvent.end.getTime() &&
                            event.title === savedEvent.title
                        )
                    ).map(event => event.id)
                }
            }
            try {
                console.log("Saving new Events:", newData)
                const newUser = await request(updateMe(newData));
                setSavedEventsList(eventsList);
                setEventsChanged(false)
            } catch (error) {
                console.error('Meeting Calendar Saving Error:', error);
                Sentry.captureException(error)
            }
        }
    }

    const discardAction = () => {
        setEventsList(savedEventsList)
        setEventsChanged(false)
    }
    const createEvent = ({ start, end }) => {
        const title = mode; // window.prompt('New Event name')
        setEventsChanged(true)
        setEventsList([
            ...eventsList,
            {
                user: user.id,
                start,
                end,
                title,
                mode
            },
        ])
    }

    const deleteEvent = (selectedEvent) => {
        // you cannot delete calendar or lunch events
        if (selectedEvent.title !== "calendar" && selectedEvent.title !== "Lunch") {
            setEventsList(eventsList.filter(event =>
                !(event.start.getTime() === selectedEvent.start.getTime() &&
                    event.end.getTime() === selectedEvent.end.getTime() &&
                    event.title === selectedEvent.title)
            ));
            setEventsChanged(true)
        }
    }

    const colorMap = {
        "Blocked": "grey",
        "Permanently Blocked": "red",
        "Preferred Time": "green",
        "Lunch": "blue"
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = colorMap[event.mode];
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    const handleChange = (event) => {
        setMode(event.target.value);
    };

    function convertTimeToMoment(timeString) {
        const [hours, minutes] = timeString.split(":");
        return moment().hours(hours).minutes(minutes);
    }



    // Get the earliest meeting date from the user object, or set a default value
    const earliestMeetingDate = user.earliest_start || new Date();

    // Use moment to get the start of the week for the earliest meeting date
    const startOfWeek = moment(earliestMeetingDate).startOf('week').toDate();

    const [date, setDate] = useState(startOfWeek);

    return (
        <Box>
            { eventsChanged ?  <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography pr={2}>Don't forget to save your data before leaving this page</Typography>
                </Grid>
            </Grid> : null }

            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography pr={2}>Select Entry Type</Typography>
                </Grid>
                <Grid item>
                    <Select
                        value={mode}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled>
                            Select mode
                        </MenuItem>
                        <MenuItem value={"Blocked"}>Block</MenuItem>
                        <MenuItem value={"Permanently Blocked"}>Block Always</MenuItem>
                        <MenuItem value={"Preferred Time"}>Whitelist</MenuItem>
                    </Select>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={saveAction}>
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={discardAction}>
                        Discard
                    </Button>
                </Grid>
            </Grid>
            <Box pt={5} pb={5}>
                <Calendar
                    selectable
                    localizer={localizer}
                    events={eventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 500}}
                    onSelectSlot={createEvent}
                    onSelectEvent={deleteEvent}
                    eventPropGetter={eventStyleGetter}
                    min={convertTimeToMoment(user.workday_starts)} // Set start time
                    max={convertTimeToMoment(user.workday_ends)} // Set end time
                    defaultView='week' // add this line
                    date={date} // Set the first day to the start of the week of the earliest meeting date
                    onNavigate={newDate => setDate(newDate)}
                    views={['week']} // Only show the week view
                />
            </Box>
        </Box>
    )
}

export default MeetingCalendar;