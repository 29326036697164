import React, {useEffect, useState} from "react";
import {Container, Grid, Typography} from "@mui/material";
import CardsRow from "./cardsrow";
import {directusClient} from "../utils/directus";
import {readItems} from "@directus/sdk";
import ErrorModal from "../utils/error_modal";
import CookieConsent from "./cookie_consent";

const Home = () => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    async function getItems() {
        try {
          const response =  await directusClient.request(readItems('test', { fields: ['*'] }));
          return response.id;
        } catch (error) {
            throw error;
        }
    }

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
        setErrorMessage(null);
    };

    useEffect(() => {
        getItems().then(response => {
            console.log('Home.js getItems: OK', response);
        }).catch(error => {
            const customMessage = `An error occurred loading test: ${error.message}`;
            setErrorMessage(customMessage);
            setIsErrorModalOpen(true);
        })
    }, []);



    return (
        <div>
            <ErrorModal
                isOpen={isErrorModalOpen}
                message={errorMessage}
                onClose={handleCloseErrorModal}
            />
            <Container maxWidth={false}
            >
                <Grid container  justifyContent="center" alignItems="center"
                      sx={{ pt: 4, pb: 4 }}>
                    {/* Column 1 */}
                    <Grid item style={{height: '100%'}}>
                        <Typography variant="h2">Intelligent Meeting Scheduler</Typography>
                    </Grid>
                    <Grid item style={{height: '100%'}}>
                        <Typography variant="h4" textAlign="center">This site is at an experimental stage and not intended for general use</Typography>
                    </Grid>
                </Grid>
                <CardsRow/>
                <CookieConsent/>
            </Container>
        </div>
    )
};

export default Home;