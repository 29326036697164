import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme'; // Import your custom theme
import AuthComponent from './authorization_state.js'

function App() {


    return (

        <ThemeProvider theme={theme}>
            <div>
                <AuthComponent/>
            </div>
        </ThemeProvider>

    );
}

export default App;
