import React, {useState, useEffect, useRef} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import ErrorBoundary from "./utils/errror_boundary";
import {directusClient} from "./utils/directus";
import {logout, readMe, refresh} from "@directus/sdk";
import Cookies from "js-cookie";
import NavBar from "./home/navbar";
import MyRoutes from "./routes";
import Footer from "./home/footer";
import Header from "./home/header";
import MessageHeader from "./home/message_header";
import * as Sentry from '@sentry/react';

export const AuthContext = React.createContext(undefined);

const AuthorizationState = () => {
    const [refreshToken, setRefreshToken] = useState(null);
    const [user, setUser] = useState(null);
    const tokenRefreshHasRunOnce = useRef(false);
    const refreshTokenState = useRef(refreshToken);
    const [message, setMessage] = useState('');
    // const [refreshCount, setRefreshCount] = useState(0);
    // const [tokenExpiryPeriod] = useState(0);
    // const [tokenExpiryDate, setTokenExpiryDate] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // function setRefreshTimer () {
    //     // set a timer to refresh the token every 5 minutes
    //     setInterval(() => {
    //         console.log('auth.js refresh timer triggered, refresh count:', refreshCount);
    //         setRefreshCount(refreshCount + 1)
    //         refreshFromToken().then(() => {
    //             console.log('auth.js refreshing from existing tokens succeeded');
    //         }).catch(error => {
    //                 console.log('auth.js refreshing from tokens failed:', error);
    //             }
    //         );
    //     },  tokenExpiryPeriod);
    //     console.log('auth.js refresh timer started');
    // }

    // useEffect(() => {
    //     console.log('auth.js token expiry period changed:', tokenExpiryPeriod);
    //     setRefreshTimer();
    // }, [tokenExpiryPeriod]);

    const processLogin = async (response) => {
        const expiresIn = response.expires; // assuming this is in milliseconds
        // const expirationDate = new Date();
        // expirationDate.setMilliseconds(expirationDate.getMilliseconds() + expiresIn);
        // setTokenExpiryDate(expirationDate);
        // setTokenExpiryPeriod(response.expires/1000*900);
        const tokenExpirationDate = new Date(response.expires_at);
        console.log('Token will expire at: ', tokenExpirationDate);
        const first20Chars = response.refresh_token.substring(0, 20);
        console.log("auth.js processLogin setting new refresh token: " + first20Chars);
        setRefreshToken(response.refresh_token);
        Cookies.set('refreshToken', response.refresh_token, {
               expires: 7, // expires in 7 days
               secure: true, // for HTTPS
               sameSite: 'strict', // CSRF protection
        });

        directusClient.request(readMe({ fields: ['*.*'] })).then(user => {
            setUser(user);
            setIsLoggedIn(true);

            //console.log('auth.js processLogin succeeded, refresh token:', refreshToken.substring(0, 20));
            return Promise.resolve('Authorization succeeded');
        }).catch(error => {
            console.log('auth.js processLogin failed to read user:', error);
            setIsLoggedIn(false);
            return Promise.reject('Authorization failed');
        })
    }

    const processLogout = async () => {
        setUser(null)
        setIsLoggedIn(false)
        try {
            const token = await directusClient.getToken();
            await directusClient.request(logout(token));
        } catch (error) {
            Sentry.captureException(error);
            console.log('profile: logout error', error)
            setMessage("Directus Logout failed")
        }
        setRefreshToken(null);
        setMessage('Logged out');
        //Cookies.remove('refreshToken');

    }

    async function refreshTokenFromCookie()
    {
        const refreshToken = Cookies.get('refreshToken');
        if (refreshToken && refreshToken !== 'undefined') {
            try {
                const response = await directusClient.request(refresh('json', refreshToken));
                console.log('auth.js refresh from cookie succeeded, new refresh token: ', response.refresh_token.substring(0, 20));
                Cookies.set('refreshToken', response.refresh_token, {
                    expires: 7, // expires in 7 days
                    secure: true, // for HTTPS
                    sameSite: 'strict', // CSRF protection
                });
                setRefreshToken(response.refresh_token);
                await directusClient.setToken(response.access_token);
                return directusClient.request(readMe({
                    fields: ["*.*"]
                })).then(user => {
                    setUser(user);
                    console.log("reading user successfull")
                    return Promise.resolve('Authorization from Cookie succeeded');
                }).catch(() => {
                    return Promise.reject("reading user failed")
                })
            } catch (error) {
               // Cookies.remove('refreshToken');
                return Promise.reject("Could not refresh from cookie");
            }
        }
        else {
            console.log('directus.js refreshTokenFromCookie no refresh token found');
            return Promise.reject('No Refresh Token Found');
        }
    }

    async function refreshFromToken() {
        try {
            // first try to use the automatic refresh
            if (refreshTokenState.current) {
                return directusClient.request(refresh('json', refreshTokenState.current)).then(response => {
                    console.log('directus.js refreshFromToken: OK', response.refresh_token, refreshTokenState.current);
                    setRefreshToken(response.refresh_token);
                    Cookies.set('refreshToken', response.refresh_token, {
                        expires: 7, // expires in 7 days
                        secure: true, // for HTTPS
                        sameSite: 'strict', // CSRF protection
                    })
                    const user =  directusClient.request(readMe(), { fields: ['*.*'] });
                    setUser(user);
                    return user;
                }).catch(error => {
                    console.log('directus.js refreshFromToken error:', error[0].message);
                    return refreshTokenFromCookie().then(()  => {
                        const user =  directusClient.request(readMe(), { fields: ['*.*'] });
                        setUser(user);
                        return user;
                    }).catch(error => {
                        setRefreshToken(null);
                        setUser(null);
                        return Promise.reject("Could not refresh token from direct & cookie" + error);
                    })
                })
            } else
                return Promise.reject("no refresh token found");
        } catch (error) {
            return Promise.reject("refresh token failed");
        }
    }

    // useEffect(() => {
    //     console.log('authorization state refresh token changed:',refreshToken)
    //     refreshTokenState.current = refreshToken;
    //     setIsLoggedIn(true);
    // }, [refreshToken]);
    //
    // useEffect(() => {
    //     console.log('authorization state isLoggedIn changed:',isLoggedIn,refreshToken);
    // }, [isLoggedIn]);

    useEffect(() => {
        if (tokenRefreshHasRunOnce.current) return;
        tokenRefreshHasRunOnce.current = true;
        // attempt to refresh from cookie only if the user is not set.
        if (user === null) return;
        // this is the only place where the timer is started
        // setRefreshTimer();
        console.log("authorization state useEffect: attempt to refresh token from cookie");
        refreshTokenFromCookie().then(response => {
                console.log('authorization state token refresh OK:', response);
            }
        ).catch(error => {
            console.log('authorization state token refresh error:', error);
            setRefreshToken(null);
            setUser(null);
        });
    }, []);


    return (
        <div>
            <Router>
                <AuthContext.Provider  value={{isLoggedIn, processLogin, processLogout,
                    user, setUser, setMessage}}>
                    <div>
                        <ErrorBoundary>
                            <Header/>
                            <NavBar/>
                            <MessageHeader message={message} setMessage={setMessage} />
                            <div>
                                <MyRoutes/>
                            </div>
                            <Footer/>
                        </ErrorBoundary>

                    </div>
                </AuthContext.Provider>
            </Router>
        </div>
    );
};

export default AuthorizationState;

