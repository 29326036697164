import {Snackbar} from "@mui/material";

function MessageHeader({message,setMessage}) {
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={message !== null}
                autoHideDuration={6000}
                onClose={() => {
                    setMessage(null)
                }}
                message={message}
            />
        </div>
    )
}

export default MessageHeader;