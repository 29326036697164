import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root') // replace '#root' with the id of your application root element

const ErrorModal = ({ message, onClose, isOpen }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Error Modal"
    >
        <h2>Error</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
    </Modal>
);

export default ErrorModal;