import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {readItems, updateUser} from "@directus/sdk";
import {directusClient} from "../utils/directus";

import {Typography} from "@mui/material";


const VerifyUser = () => {
  const [verificationStatus, setVerificationStatus] = useState('');
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const user_id = query.get('id');

    async function getUser(user_id) {
      try {
          return await directusClient.request(
              readItems('validation_keys', { filter: { user_id: user_id } }));
      } catch (error) {
        console.error('error', error);
        setVerificationStatus('Could not find verification key for user');
      }
    }

    async function updateUserStatus(user_id) {
      try {
        return await directusClient.request(
            updateUser( user_id,
                {
                  status: 'active'
                }));
      } catch (error) {
        console.error('error', error);
        setVerificationStatus('Could not find user');
      }
    }

    if (user_id && token) {
      console.log('user_id', user_id);
      getUser(user_id).then(response => {
        response.some(element => {
              console.log('element', element);
              if (element.validation_key === token) {
                updateUserStatus(user_id).then(response => {
                  console.log('user update', response);
                  setVerificationStatus('User verified');
                  return true;
                })
              }
              return false;
            }
        )})
    }
  }, [location]);

  return (
    <div>
      <Typography pt={10} textAlign="center">Verification Status  </Typography>
      <Typography pb={10} textAlign="center">{verificationStatus} </Typography>

    </div>
  );
};

export default VerifyUser;
