import React, {useEffect, useState} from 'react';
import {Typography, Switch, FormControlLabel, Grid} from '@mui/material';
import {directusClient} from "../utils/directus";
import {updateItem} from "@directus/sdk";
function CalendarInfo({calendars}) {
    const [calendarsState, setCalendarsState] = useState(calendars);

    // Update calendarsState whenever calendars prop changes
    useEffect(() => {
        setCalendarsState(calendars);
    }, [calendars]);


    async function updateCalendarsState(calendar) {
        directusClient.request(updateItem('calendar_info', calendar.id, calendar))
            .catch(
            error => { console.log('error updating calendar info:', error)}
        );
    }
    const handleSwitchChange = (calendarId) => {
        // Create a new array by mapping over the calendarsState
        const updatedCalendars = calendarsState.map((calendar) => {
            // If the current calendar's id matches the passed in calendarId
            if (calendar.id === calendarId) {
                // Return a new object with all of the current calendar's properties,
                // but with the enabled property toggled
                updateCalendarsState({...calendar, enabled: !calendar.enabled});
                return { ...calendar, enabled: !calendar.enabled };
            }
            // If the current calendar's id doesn't match, return the current calendar as is
            return calendar;
        });

        // Set the calendarsState to the new array
        setCalendarsState(updatedCalendars);
    };

    return (
        <div>
            <h1>Outlook Calendar Info</h1>

            {calendarsState.map((calendar) => {
                if (calendar.type === 'OutlookLocal') {
                  return (  <Grid container alignItems="center" key={calendar.name}>
                        <Grid item xs={6}>
                            <Typography variant="h6">{calendar.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={calendar.enabled}
                                        onChange={() => handleSwitchChange(calendar.id)}
                                        name="enabled"
                                        color="primary"
                                    />
                                }
                                label={calendar.enabled ? 'Enabled' : 'Disabled'}
                            />
                        </Grid>
                    </Grid>
                  )
                }
                else {
                    console.log('calendar type not supported:', calendar.type);
                    return null;
                }

            })}
        </div>
    );
}

export default CalendarInfo;