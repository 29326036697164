import React from 'react';
import {Box, List, ListItem, ListItemIcon, Typography} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

function AboutListItem({children, itemKey}) {
    return (
        <ListItem key={itemKey}>
            <ListItemIcon>
                <CircleIcon  fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">{children}</Typography>
        </ListItem>
    );
}

function AboutListTitle({title}) {
    return (
        <>
            {title !== "None" && (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography variant="h3">{title}</Typography>
                </Box>
            )}
        </>
    );

}

function AboutList({title, children}) {
    return (
        <React.Fragment>
            <AboutListTitle title={title} />
            <List style={{ paddingLeft: 40 }}>
                {children.map((child, index) => (
                    Array.isArray(child) ?
                        <AboutList key={index} title={child[0]} children={child.slice(1)} /> :
                        <AboutListItem key={index}>{child}</AboutListItem>
                ))}
            </List>
        </React.Fragment>
    );
}
function About() {
    return (
        <Box pt={5}>
            <AboutList
                title="Why ?"
                children={[
                    'Anything is better than what we do now.',
                    'People and organizations waste substantial resources to schedule meetings with existing tools.',
                    'Despite this investment in time, effort and money, scheduling of meetings is still inefficient'
                ]}/>

            <AboutList
                title="Plan"
                children={[
                    'Replace the present survey based scheduling process with a rule based process.',
                    'Schedule meetings of defined groups on the fly.',
                    'Reduce the manual intervention to (almost) zero by using:',
                    [
                        'None',
                        'calendar information of participants (no personal data is shared)',
                        'person- and group- specific time preferences (whitelists and blacklists)',
                        'AI for meeting priority and time slot selection'
                    ]
                ]}/>

            <AboutList
                title="RoadMap"
                children={[
                    'Present Stage: Implement basic functionality for scheduling meetings the traditional way as a fallback',
                    'Integrate with Outlook, Google Calendar, and other calendar systems',
                    'Implement whitelists and blacklists.',
                ]}/>

          //  <AboutListTitle title={'Frequently asked questions'} />
        </Box>
    );
}

export default About;