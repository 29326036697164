import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./home/home";
import LoginPage from "./account/login_page";
import Register from "./account/register";
import ForgotPassword from "./account/password_reset";
import Profile from "./account/profile";
import TimeSlots from "./calendar/time_slots";
import About from "./home/about";
import MeetingList from "./meeting/meeting_list";
import SinglePage from "./home/single_page";
import Impressum from "./home/impressum";
import Contact from "./home/contact";
import Availability from "./account/availability";
import VerifyUser from "./account/verify";
import Meeting from "./meeting/meeting";
import TermsOfService from "./home/terms_of_service";
import PrivacyPolicy from "./home/privacy_policy";
import Test from "./utils/test";
import FaqPage from "./home/faq";
import ReadCalendars from "./utils/test";


const Page404 = () => {
    return <h1>Sorry not implemented</h1>
}

export const MyRoutes = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot_password" element={<ForgotPassword/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/availability" element={<Availability/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/time_slots" element={<TimeSlots/>} />
        <Route path="/meeting_list" element={<MeetingList/>} />
        <Route path="/meeting/:id" element={<Meeting/>} />
        <Route path="/terms_of_service" element={<TermsOfService/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/verify" element={<VerifyUser/>} />
        <Route path="/test" element={<Test/>} />
        <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
        <Route path="/impressum" element={<Impressum/>} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/test" element={<ReadCalendars />} />
        <Route path="/SinglePage/:page_name" element={<SinglePage />} />
        <Route path="*" element={<Page404/>} />

    </Routes>);
export default MyRoutes;
