import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { authentication, createDirectus, rest } from '@directus/sdk';

export const directusUrl = "https://meet-fast.com" // process.env.REACT_APP_DIRECTUS_SERVER;

export const directusClient = createDirectus(directusUrl)
    .with(authentication('json'))
    // .with(authentication('json', { autoRefresh: false, credentials: 'include' }))
    .with(rest({ credentials: 'include' }))

function useDirectusRequest() {
    const navigate = useNavigate();

    async function request(props) {
        // Decode the JWT to get its payload
        try {
            const token = await directusClient.getToken()
            const decodedToken = jwtDecode(token);

            // Get the current time and convert to Unix timestamp
            const currentTime = Date.now() / 1000; // convert to seconds

            // Check if the token has expired
            const expired = decodedToken.exp < currentTime;
            if (expired) {
                navigate('/login?msg="token expired"');
                return Promise.reject("Token expired");
            }
            return await directusClient.request(props);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    return request;
}

export default useDirectusRequest;