import axios from "axios";

async function getCalendarInfo(user, start_date, end_date) {
    // TODO remove hard coded address for flows
    if (user === null) return;
    try {
        const response = await axios.post('https://www.meet-fast.com/flows/trigger/c95710a1-a961-46fc-b9a2-d00bd8f2223c',
            {  message: user.id,
                start_date: start_date,
                end_date: end_date})

        //const eventsObj = JSON.parse(response.data.events)
        const events = Array.isArray(response.data.events) ? response.data.events : [];
       
        const blockedTimeSlots = events.map(slot => ({
            ...slot,
            start: new Date(slot.start),
            end: new Date(slot.end),
            mode: 'blocked',
            title: 'calendar'
        }));
        console.log("blockedTimeSlots", blockedTimeSlots)
        return blockedTimeSlots;
    } catch(error) {
        return Promise.reject(error);
    }
}

export default getCalendarInfo;