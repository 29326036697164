import React, {useState} from 'react';
import axios from 'axios';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const handleForgotPassword = async () => {
        setLoading(true);
        // TODO password reset with sdk
        const directus = process.env.REACT_APP_DIRECTUS_URL;
        try {
            const password_query = directus + '/auth/password/request';
            const response = await axios.post(password_query, {
                email,
            });
            console.log('forgot password:',response.data)
            // If successful, Directus will send a password reset email to the user.
            if (response.data) {
                setMessage('A password reset link has been sent to your email address.');
            }
        } catch (error) {
            // Handle errors (e.g., email not found in the system)
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Forgot Password</h1>
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button disabled={loading} onClick={handleForgotPassword}>
                {loading ? 'Sending...' : 'Send Password Reset Link'}
            </button>

            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPassword;
