import {Box} from "@mui/material";

function PrivacyPolicy() {
    const string = "<h1> Privacy Policy </h1>\n" +
        "\n" +
        "This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the Service and tells You about Your privacy rights and how the law protects You.\n" +
        "\n" +
        "We use your personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.\n" +
        "\n" +
        "<h2> Interpretation and Definitions</h2>\n" +
        "Interpretation\n" +
        "\n" +
        "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.\n" +
        "\n" +
        "Definitions\n" +
        "\n" +
        "For the purposes of this Privacy Policy:\n" +
        "\n" +
        "Account means a unique account created for You to access our Service or parts of our Service.\n" +
        "\n" +
        "Affiliate means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.\n" +
        "\n" +
        "Application means the software program provided by the Company downloaded by You on any electronic device, named TeachIt\n" +
        "\n" +
        "User Content means all data you have generated in TeachIt.\n" +
        "\n" +
        "Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to TeachIt.\n" +
        "\n" +
        "Country refers to: Baden-Württemberg, Germany\n" +
        "\n" +
        "Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.\n" +
        "\n" +
        "Personal Data is any information that relates to an identified or identifiable individual.\n" +
        "\n" +
        "Service refers to the Application.\n" +
        "\n" +
        "Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.\n" +
        "\n" +
        "Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).\n" +
        "\n" +
        "You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.\n" +
        "\n" +
        "<h2> Collecting and Using Your Personal Data</h2>\n" +
        "<h3> Types of Data Collected</h3>\n" +
        "Personal Data\n" +
        "\n" +
        "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:\n" +
        "\n" +
        "Email address\n" +
        "\n" +
        "First name and last name\n" +
        "\n" +
        "Bank account information in order to pay for products and/or services within the Service\n" +
        "\n" +
        "Usage Data\n" +
        "\n" +
        "When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:\n" +
        "\n" +
        "Date of birth\n" +
        "Passport or National ID card\n" +
        "Bank card statement\n" +
        "Other information linking You to an address\n" +
        "Usage Data\n" +
        "\n" +
        "Usage Data is collected automatically when using the Service.\n" +
        "\n" +
        "Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. It includes also all data which you provide to us while using the service, e.g. by filling out forms on the website.\n" +
        "\n" +
        "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.\n" +
        "\n" +
        "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.\n" +
        "\n" +
        "<h3> Use of Your Personal Data</h3>\n" +
        "\n" +
        "The Company may use Personal Data for the following purposes:\n" +
        "\n" +
        "To provide and maintain our Service, including to monitor the usage of our Service.\n" +
        "To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.\n" +
        "For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.\n" +
        "To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation. We will use only the communication channels you have provided. A functional email address is required to use the service. \n" +
        "To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information. You may opt out of this service.\n" +
        "To manage Your requests: To attend and manage Your requests to Us.\n" +
        "To deliver targeted advertising to You: We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.\n" +
        "For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.\n" +
        "For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.\n" +
        "\n" +
        "We may share Your personal information in the following situations:\n" +
        "\n" +
        "With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to advertise on third party websites to You after You visited our Service, for payment processing, to contact You.\n" +
        "For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.\n" +
        "With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. \n" +
        "With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.\n" +
        "With other users: when You share personal information or otherwise interactwith other users, such information may be viewed by all users and may be publicly distributed outside.\n" +
        "With Your consent: We may disclose Your personal information for any other purpose with Your consent.\n" +
        "Retention of Your Personal Data\n" +
        "\n" +
        "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.\n" +
        "\n" +
        "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.\n" +
        "\n" +
        "<h3> Transfer of Your Personal Data</h3>\n" +
        "\n" +
        "Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.\n" +
        "\n" +
        "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.\n" +
        "\n" +
        "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.\n" +
        "\n" +
        "<h3> Disclosure of Your Personal Data</h3>\n" +
        "Business Transactions\n" +
        "\n" +
        "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.\n" +
        "\n" +
        "Law enforcement\n" +
        "\n" +
        "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).\n" +
        "\n" +
        "Other legal requirements\n" +
        "\n" +
        "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:\n" +
        "\n" +
        "Comply with a legal obligation\n" +
        "Protect and defend the rights or property of the Company\n" +
        "Prevent or investigate possible wrongdoing in connection with the Service\n" +
        "Protect the personal safety of Users of the Service or the public\n" +
        "Protect against legal liability\n" +
        "Security of Your Personal Data\n" +
        "\n" +
        "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.\n" +
        "\n" +
        "Using your Profile Data, User Content and Posts\n" +
        "\n" +
        "Your profile is visible to Members and customers of our Services who know your email address. Subject to your settings, it can also be visible to others on or off of our Services (e.g., Visitors to our Services or users of third- party search engines). \n" +
        "\n" +
        "Posts, Likes, Follows, Comments, Messages\n" +
        "\n" +
        "Our Services allow viewing and sharing information including through service-related pages, posts, likes, follows and comments.\n" +
        "\n" +
        "When you share meting data, an article or a post (e.g., an update, image, video or article) publicly it can be viewed by everyone and re-shared anywhere (subject to your settings). Members, Visitors and others will be able to find and see your publicly-shared content, including your name and photo if you have provided one. If you have not provided your name others will be able to see your account name.\n" +
        "In a group, posts are visible to others in the group. Your membership in groups is public and part of your profile, but you can change visibility in your settings.\n" +
        "Any information you share through companies’ or other organizations’ pages on our Services will be viewable by it and others who visit those pages.\n" +
        "When you follow a person or organization, you are visible to others and that “page owner” as a follower.\n" +
        "We let senders know when you act on their message, subject to your settings where applicable.\n" +
        "Subject to your settings, we let a Member know when you view their profile.\n" +
        "When you like or re-share or comment on another’s content (including ads), others will be able to view these “social actions” and associate it with you (e.g., your name, profile and photo if you provided it).\n" +
        "<h2> User Content</h2> \n" +
        "\n" +
        "The Company with store and distribute your user content, including any personal information you have provided therein, according to the license you may have granted the Company in the terms of use.\n" +
        "\n" +
        "Detailed Information on the Processing of Your Personal Data\n" +
        "\n" +
        "The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.\n" +
        "\n" +
        "<h2> Analytics</h2>\n" +
        "\n" +
        "We may use third-party Service providers to monitor and analyze the use of our Service.\n" +
        "\n" +
        "Google Analytics\n" +
        "\n" +
        "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.\n" +
        "\n" +
        "You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy\n" +
        "\n" +
        "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy\n" +
        "\n" +
        "Advertising\n" +
        "\n" +
        "We may use Service Providers to show advertisements to You to help support and maintain Our Service.\n" +
        "\n" +
        "Google AdSense & DoubleClick Cookie\n" +
        "\n" +
        "Google, as a third party vendor, uses cookies to serve ads on our Service. Google’s use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.\n" +
        "\n" +
        "You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: http://www.google.com/ads/preferences/\n" +
        "\n" +
        "Email Marketing\n" +
        "\n" +
        "We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.\n" +
        "\n" +
        "We may use Email Marketing Service Providers to manage and send emails to You.\n" +
        "\n" +
        "Mailchimp\n" +
        "\n" +
        "Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.\n" +
        "\n" +
        "For more information on the privacy practices of Mailchimp, please visit their Privacy policy: https://mailchimp.com/legal/privacy/\n" +
        "\n" +
        "<h2> Payments</h2>\n" +
        "\n" +
        "We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).\n" +
        "\n" +
        "We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.\n" +
        "\n" +
        "PayPal\n" +
        "\n" +
        "Their Privacy Policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full\n" +
        "\n" +
        "When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.\n" +
        "\n" +
        "Behavioral Remarketing\n" +
        "\n" +
        "The Company uses remarketing services to advertise to You after You accessed or visited our Service. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and understand how You use our Service so that We can improve our Service to reflect Your interests and serve You advertisements that are likely to be of more interest to You.\n" +
        "\n" +
        "These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies and to enable Us to:\n" +
        "\n" +
        "Measure and analyze traffic and browsing activity on Our Service\n" +
        "Show advertisements for our products and/or services to You on third-party websites or apps\n" +
        "Measure and analyze the performance of Our advertising campaigns\n" +
        "\n" +
        "Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the following third-party tools to decline the collection and use of information for the purpose of serving You interest-based advertising:\n" +
        "\n" +
        "The NAI’s opt-out platform: http://www.networkadvertising.org/choices/\n" +
        "The EDAA’s opt-out platform http://www.youronlinechoices.com/\n" +
        "The DAA’s opt-out platform: http://optout.aboutads.info/?c=2&lang=EN\n" +
        "\n" +
        "You may opt-out of all personalized advertising by enabling privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for more information.\n" +
        "\n" +
        "We may share information, such as hashed email addresses (if available) or other online identifiers collected on Our Service with these third-party vendors. This allows Our third-party vendors to recognize and deliver You ads across devices and browsers. To read more about the technologies used by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed below.\n" +
        "\n" +
        "The third-party vendors We use are:\n" +
        "\n" +
        "Google Ads (AdWords)\n" +
        "\n" +
        "Google Ads (AdWords) remarketing service is provided by Google Inc.\n" +
        "\n" +
        "You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads\n" +
        "\n" +
        "Google also recommends installing the Google Analytics Opt-out Browser Add-on – https://tools.google.com/dlpage/gaoptout – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.\n" +
        "\n" +
        "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy\n" +
        "\n" +
        "LinkedIn\n" +
        "\n" +
        "Their Privacy Policy can be viewed at https://www.linkedin.com/legal/privacy-policy\n" +
        "\n" +
        "<h2> Children’s Privacy</h2>\n" +
        "\n" +
        "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.\n" +
        "\n" +
        "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.\n" +
        "\n" +
        "<h2> Links to Other Websites</h2> \n" +
        "\n" +
        "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.\n" +
        "\n" +
        "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.\n" +
        "\n" +
        "<h2> Changes to this Privacy Policy</h2>\n" +
        "\n" +
        "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.\n" +
        "\n" +
        "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the bottom of this Privacy Policy.\n" +
        "\n" +
        "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.\n" +
        "\n" +
        "<h2> Contact Us<h2> \n" +
        "\n" +
        "If you have any questions about this Privacy Policy, You can contact us."


    // Split the string into lines, wrap each line in a <p> tag, and join them back into a single string
    const html = string.split('\n').map(line => `<p>${line}</p>`).join('');

    return (

        <Box pr={5} pl={5}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
  );
}

export default PrivacyPolicy;