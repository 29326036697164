import {Grid, Typography} from "@mui/material";

function Impressum() {
    return (
        <Grid container pr={5} pt={2} pl={5}>
            <Grid item xs={12}>
                <Typography variant="h2"> Impressum </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6"> Verantwortlich: </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> Wolfgang Wenzel </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" pt={3}> Address: </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> TeachIt UG </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> c/o Wolfgang Wenzel </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> Im Grün 38 </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> 76646 Bruchsal </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1"> Germany </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" pt={3}> Telefon: +49 (0)15114295504 </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" pt={3}> Email: webmaster@meet-fast.com </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" pt={3}> Internet: www.meet-fast.com </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" pt={3}> Last Updated: 2.3.2024 </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" pt={3}> You can contact us by sending an email to webmaster@meet-fast.com or by using the contact form. </Typography>
            </Grid>
        </Grid>
    )
}

export default Impressum;