import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../authorization_state";
import {readItems, updateMe} from "@directus/sdk";
import {directusClient} from "../utils/directus";
import {
    AlertTitle, Box, Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField, Typography
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import Alert from "@mui/material/Alert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import CalendarInfo from "./calendar_info";
import Loading from "../utils/loading";

const Profile = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {isLoggedIn, user, setUser, processLogout} = useContext(AuthContext);
    const [passwordChangeMode, setPasswordChangeMode] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [passwordError, setPasswordError] = useState(true);
    const [calendars, setCalendars] = useState([]);

    const key_map = {
        'first_name' : 'First Name',
        'last_name' : 'Last Name',
        'email' : 'Email',
    };

    const commonStyles = {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
    }

    const saveUser = async () => {
        if (user) {
           await directusClient.request(updateMe(
                {
                    first_name: user.first_name,
                    last_name: user.last_name,
                }
            ));

        }
    }

    const savePassword = async () => {
        if (user) {
            await directusClient.request(updateMe(
                {
                    password: password,
                }
            )).then(response => {
                console.log('profile.js savePassword:', response);
            }).catch(error => { console.log('profile.js savePassword error:', error); });

        }
    }

    const handleUserChange = ( event, key) => {
        const updatedData = { ...user };
        updatedData[key] = event.target.value;
        setUser(updatedData);
    };


    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    }

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);

        if (newPassword.length >= 6 && newPassword !== '*****') {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
    }
    // Handle logout
    const logout = async () => {
        try {
            processLogout()
        } catch (e) {
            console.log('profile: logout error',e)
        }
        navigate('/');
    };

    async function readCalendars() {
        directusClient.request(readItems('calendar_info', {
            filter: {
                user: user.id
            }
        })).then(response => {
            console.log('profile.js readCalendars:', response);
            setCalendars(response);
        }).catch(error => {
            console.log('profile.js readCalendars error:', error);

        })
    }

    // make sure that getUser is called only when isLoggedIn changes
    // useEffect(() => {
    //     if (isLoggedIn && directusClient !== null)
    //         getUser(2);
    //     else
    //         setUser(null);
    // },[isLoggedIn, directusClient]);

    useEffect(() => {
        if (user !== null) {
            readCalendars()
        }
    }, [user]);

    function UserTextField({item_key, readOnly}) {
        return (
            <ListItem key={item_key}>
                <ListItemText>
                    <TextField
                        label={key_map[item_key]}
                        value={user[item_key]}
                        onChange={(event) => handleUserChange(event, item_key)}
                        InputProps={{ readOnly: readOnly }}
                    />
                </ListItemText>
            </ListItem>
        )
    }

    // if (user === null) {
    //     logout()
    // }

    function UserProfile() {
        return ( user ?
                <List sx={{ '& .MuiListItem-root': { justifyContent: 'flex-start' } }}>
                    <ListItem>
                        <ListItemText>
                            <TextField
                                label={key_map['first_name']}
                                value={user['first_name']}
                                onChange={(event) => handleUserChange(event, 'first_name')}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label={key_map['last_name']}
                                value={user['last_name']}
                                onChange={(event) => handleUserChange(event, 'last_name')}
                                InputProps={{ readOnly: true }}
                                style={{ marginLeft: '10px' }} // Add some space between the fields
                            />
                        </ListItemText>
                    </ListItem>
                    <UserTextField item_key='email' readOnly={true} />
                </List> : <Loading/>
        )
    }

    function ProfileHeader({title}) {
        return (
            <Box
                pt = {2} display="flex" flexDirection="row"
            >
                <Typography variant="h4"  style={commonStyles}>{title}</Typography>
            </Box>
        )
    }

    return (
        isLoggedIn ? (
        <Grid container style={{ backgroundColor: theme.palette.background.default }} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={commonStyles}
                  //  minHeight="100vh" // Center vertically on the screen
                >
                   <ProfileHeader title="General Information"/>
                    <List>
                        <ListItem>
                            <UserProfile/>
                        </ListItem>
                        <ListItem>
                            <CalendarInfo calendars={calendars} />
                        </ListItem>
                        <ListItem>
                            { isLoggedIn ?
                                <List>
                                    <ListItem>
                                        <ListItemButton
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                borderRadius: theme.shape.borderRadius,
                                            }}
                                            onClick={() => { navigate('/time_preferences')}}
                                        >
                                           Set Time Preferences
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        { !passwordChangeMode ?
                                            <ListItemButton
                                                style={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    borderRadius: theme.shape.borderRadius,
                                                }}
                                                onClick={() => setPasswordChangeMode(!passwordChangeMode)}>
                                                Change Password </ListItemButton> :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    label="Password"
                                                    value= {password}
                                                    type={passwordVisibility ? 'text' : 'password'}
                                                    onChange={handlePasswordChange}
                                                    InputProps={{
                                                        readOnly: false,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                                                    {passwordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment> )
                                                    }}
                                                    error={passwordError}
                                                    helperText={passwordError ? 'Password must be at least 6 characters and not "*****".' : ''}

                                                />
                                                <IconButton onClick={() => {
                                                    savePassword();
                                                    setPasswordChangeMode(!passwordChangeMode);
                                                    setPasswordVisibility(false);
                                                }}>
                                                    <SaveIcon />
                                                </IconButton>
                                            </div>
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <ListItemButton onClick={logout}
                                                        align="center"
                                                        style={{
                                                            backgroundColor: theme.palette.primary.main,
                                                            color: theme.palette.primary.contrastText,
                                                            borderRadius: theme.shape.borderRadius,
                                                        }}>Logout</ListItemButton>
                                    </ListItem>
                                </List> :
                                // using MUI display an error message
                                <Alert severity="info">
                                    <AlertTitle>Information</AlertTitle>
                                    You are not logged in
                                </Alert>

                            }
                        </ListItem>
                    </List>
                </Box>
            </Grid>
        </Grid> ) : (    <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            You are not logged in
        </Alert> )

    );

}

export default Profile;
