import {Grid, Switch, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {TimeField} from "@mui/x-date-pickers/TimeField";

function WorkingPeriod({information, saveInformation}) {

    function setSkipLunch(value) {
        if (value)
            saveInformation({skip_lunch: "yes"});
        else
            saveInformation({skip_lunch: "no"});
    }

    function convertTimeToMoment(timeString) {
        const [hours, minutes] = timeString.split(":");
        return moment().hours(hours).minutes(minutes);
    }

    function TimeSelector({value, setValue, label}) {
        return <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="timePicker">
                <TimeField
                    defaultValue={value}
                    label={label}
                    value={value}
                    format="HH:mm" // Set the format to 24-hour
                    onBlur={(e) => {
                        setValue(e.target.value);
                    }}

                />
            </div>
        </LocalizationProvider>;
    }

    function setWorkDayStarts(moment) {   saveInformation({workday_starts: moment}); }
    function setLunchStarts(moment) { saveInformation({lunch_starts : moment }) }
    function setLunchEnds(moment) { saveInformation({lunch_ends : moment}) }
    function setWorkDayEnds(moment) { saveInformation({workday_ends: moment}) }

    return (
        <React.Fragment>
            <Grid item xs={3}>
                <Typography pr={5}> Work Day Starts</Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {TimeSelector(
                        {value: convertTimeToMoment(information.workday_starts),
                            setValue: setWorkDayStarts, label: "Day Starts"})}
                </div>
            </Grid>
            <Grid item xs={3}>
                <Typography pr={5}> Work Day Ends
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {TimeSelector({value:convertTimeToMoment(information.workday_ends),
                        setValue: setWorkDayEnds, label: "Day Ends"})}
                </div>
            </Grid>
            <Grid item xs={3}>
                <Typography pr={5}> Skip Lunch</Typography>
            </Grid>
            <Grid item xs={9}>
                <Switch
                    checked={information.skip_lunch === "yes"}
                    onChange={(event) => setSkipLunch(event.target.checked)}
                />
            </Grid>
            {information.skip_lunch === "yes" ?
                <React.Fragment>
                    <Grid item xs={3}>
                        <Typography> Lunch Starts</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {TimeSelector(
                            {value:convertTimeToMoment(information.lunch_starts),
                                setValue: setLunchStarts, label: "Lunch Starts"})}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Lunch Ends
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {TimeSelector(
                            {value:convertTimeToMoment(information.lunch_ends),
                                setValue: setLunchEnds, label: "Lunch Ends"})}
                    </Grid>
                </React.Fragment> : null }
        </React.Fragment>
    )
}

export default WorkingPeriod;