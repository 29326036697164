import {Box} from "@mui/material";

function TermsOfService() {
    const html = "Terms and Conditions\n" +
        "\n" +
        "Welcome to meet-fast!\n" +
        "\n" +
        "These terms and conditions outline the rules and regulations for the use of the meet-fast.com Website, provided by TeachIt UG, henceforth referred to as Company.\n" +
        "\n" +
        "By accessing this website, you accept these terms and conditions. Do not continue to use meet-fast if you do not agree to take all of the terms and conditions stated on this page.\n" +
        "\n" +
        "License\n" +
        "\n" +
        "Unless otherwise stated, The Company and/or its licensors own the intellectual property rights for all material on meet-fast. All intellectual property rights are reserved. You may access this from meet-fast for your own personal use subjected to restrictions set in these terms and conditions.\n" +
        "\n" +
        "You must not:\n" +
        "\n" +
        "Copy or republish material from meet-fast\n" +
        "Sell, rent, or sub-license material from meet-fast\n" +
        "Reproduce, duplicate or copy material from meet-fast\n" +
        "Redistribute content from meet-fast\n" +
        "\n" +
        "This Agreement shall begin on the date hereof.\n" +
        "\n" +
        "Content\n" +
        "\n" +
        "Parts of this website offer users an opportunity to post information in certain areas of the website. The Company does not filter, edit, publish or review User-Generated-Content before their presence on the website. User-Generated-Content do not reflect the views and opinions of The Company, its agents, and/or affiliates. User-Generated-Content reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, The Company shall not be liable for the User-Generated-Content or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the User-Generated-Content on this website.\n" +
        "\n" +
        "The Company reserves the right to monitor all user-generated content and remove any User-Generated-Content that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions. The Company may terminate your account when User-Generated-Content that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.\n" +
        "\n" +
        "You warrant and represent that:\n" +
        "\n" +
        "You are entitled to post the User-Generated-Content on our website and have all necessary licenses and consents to do so;\n" +
        "The User-Generated-Content do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;\n" +
        "The User-Generated-Content do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy.\n" +
        "The User-Generated-Content will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.\n" +
        "\n" +
        "You hereby grant The Company a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your User-Generated-Content in any and all forms, formats, or media.\n" +
        "\n" +
        "Content Liability\n" +
        "\n" +
        "We shall not be held responsible for any User-Generated-Content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.\n" +
        "\n" +
        "Content Restrictions\n" +
        "\n" +
        "We are not responsible for the entries, information or content of the Application and Content of the users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using your account.\n" +
        "\n" +
        "You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:\n" +
        "\n" +
        "Unlawful or promoting unlawful activity.\n" +
        "Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.\n" +
        "Spam, machine generated content or randomly generated content, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.\n" +
        "Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.\n" +
        "Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.\n" +
        "Impersonating any person or entity including the Company and its employees or representatives.\n" +
        "Violating the privacy of any third person.\n" +
        "False information and features.\n" +
        "\n" +
        "We reserve the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Agreement, refuse or remove any Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Application and Content if You post such objectionable Content.\n" +
        "\n" +
        "As the Company cannot control all content posted by users and/or third parties on the Application and Content, you agree to use the Application and Content at your own risk. You understand that by using the Application and Content You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.\n" +
        "\n" +
        "We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.\n" +
        "\n" +
        "Third-Party Services\n" +
        "\n" +
        "The Application and Content may display, include or make available third-party content (including data, information, Application and Contents and other products services) or provide links to third-party websites or services.\n" +
        "\n" +
        "You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.\n" +
        "\n" +
        "You must comply with applicable Third parties’ Terms of agreement when using the Application and Content. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties’ Terms and conditions.\n" +
        "\n" +
        "Cookies\nThis site uses functional cookies. By using this site you accept this cookie policy\n" +
        "Term and Termination\n" +
        "\n" +
        "This Agreement shall remain in effect until terminated by You or the Company. The Company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.\n" +
        "\n" +
        "This Agreement will terminate immediately, without prior notice from the Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting your account on the website.\n" +
        "\n" +
        "Upon termination of this Agreement, You shall cease all use of the Application and Content and delete all copies of the Application and Content from your Device.\n" +
        "\n" +
        "Termination of this Agreement will not limit any of the Company’s rights or remedies at law or in equity in case of breach by You (during the term of this Agreement) of any of your obligations under the present Agreement.\n" +
        "\n" +
        "Indemnification\n" +
        "\n" +
        "You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys’ fees, due to or arising out of your: (a) use of the Application and Content; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.\n" +
        "\n" +
        "No Warranties\n" +
        "\n" +
        "The Application and Content is provided to You “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application and Content, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application and Content will meet your requirements, achieve any intended results, be compatible or work with any other software, Application and Contents, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.\n" +
        "\n" +
        "Without limiting the foregoing, neither the Company nor any of the company’s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application and Content, or the information, content, and materials or products included thereon; (ii) that the Application and Content will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application and Content; or (iv) that the Application and Content, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.\n" +
        "\n" +
        "Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company shall be solely responsible for such warranty.\n" +
        "\n" +
        "Limitation of Liability\n" +
        "\n" +
        "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You for the Application and Content or through the Application and Content or 100 USD if You haven’t purchased anything through the Application and Content.\n" +
        "\n" +
        "To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Application and Content, third-party software and/or third-party hardware used with the Application and Content, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.\n" +
        "\n" +
        "Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You.\n" +
        "\n" +
        "Severability and Waiver\n" +
        "Severability\n" +
        "\n" +
        "If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.\n" +
        "\n" +
        "Waiver\n" +
        "\n" +
        "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party’s ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.\n" +
        "\n" +
        "Product Claims\n" +
        "\n" +
        "The Company does not make any warranties concerning the Application and Content.\n" +
        "\n" +
        "Legal Compliance\n" +
        "\n" +
        "You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a “terrorist supporting” country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.\n" +
        "\n" +
        "Changes to this Agreement\n" +
        "\n" +
        "The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.\n" +
        "\n" +
        "By continuing to access or use the Application and Content after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application and Content.\n" +
        "\n" +
        "Cookies\n" +
        "\n" +
        "The website uses cookies to help personalize your online experience. By accessing meet-fast, you agreed to use the required cookies.\n" +
        "\n" +
        "A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.\n" +
        "\n" +
        "We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. By accepting the terms of use you accept the use of these cookies. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.\n" +
        "\n" +
        "Hyperlinking to our Content\n" +
        "\n" +
        "The following organizations may link to our Website without prior written approval:\n" +
        "\n" +
        "Government agencies;\n" +
        "Search engines;\n" +
        "News organizations;\n" +
        "Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and\n" +
        "System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.\n" +
        "\n" +
        "These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.\n" +
        "\n" +
        "We may consider and approve other link requests from the following types of organizations:\n" +
        "\n" +
        "commonly-known consumer and/or business information sources;\n" +
        "dot.com community sites;\n" +
        "associations or other groups representing charities;\n" +
        "online directory distributors;\n" +
        "internet portals;\n" +
        "accounting, law, and consulting firms; and\n" +
        "educational institutions and trade associations.\n" +
        "\n" +
        "We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of the Componay; and (d) the link is in the context of general resource information.\n" +
        "\n" +
        "These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.\n" +
        "\n" +
        "If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to The Company. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.\n" +
        "\n" +
        "Approved organizations may hyperlink to our Website as follows:\n" +
        "\n" +
        "By use of our corporate name; or\n" +
        "By use of the uniform resource locator being linked to; or\n" +
        "Using any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.\n" +
        "\n" +
        "No use of The Company's logo or other artwork will be allowed for linking absent a trademark license agreement.\n" +
        "\n" +
        "Reservation of Rights\n" +
        "\n" +
        "We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.\n" +
        "\n" +
        "Removal of links from our website\n" +
        "\n" +
        "If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.\n" +
        "\n" +
        "Governing Law\n" +
        "\n" +
        "The laws of the Federal Republic of Germany, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application and Content. Your use of the Application and Content may also be subject to other local, state, national, or international laws.\n" +
        "\n" +
        "Entire Agreement\n" +
        "\n" +
        "The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and Content and supersedes all prior and contemporaneous written or oral agreements between You and the Company.\n" +
        "\n" +
        "You may be subject to additional terms and conditions that apply when You use or purchase other Company’s services, which the Company will provide to You at the time of such use or purchase."

    // Split the string into lines, wrap each line in a <p> tag, and join them back into a single string
    const newHtml = html.split('\n').map(line => `<p>${line}</p>`).join('');

    return (
        <Box pr={5} pl={5}>
            <div dangerouslySetInnerHTML={{ __html: newHtml }} />
        </Box>
    )
}

export default TermsOfService;